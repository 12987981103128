import {Component} from "react";
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';

const Input = styled('input')({
    display: 'none',
});

class ImageToPdf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Initially, no file is selected
            selectedFile: null,
            converting: false,
            atLeastOneDone: false
        };
    }

    resetForm = () => {
        this.setState({selectedFile: null, converting: false, atLeastOneDone:true})
    }

    // On file select (from the pop up)
    onFileChange = event => {
        // Update the state
        this.setState({selectedFile: event.target.files[0]});
    };

    // On file upload (click the upload button)
    onFileUpload = (config) => {
        this.setState({converting:true})
        // Create an object of formData
        const formData = new FormData();
        const userFileName = this.state.selectedFile.name;
        // Update the formData object
        formData.append(
            "myFile",
            this.state.selectedFile,
            userFileName
        );

        // Details of the uploaded file
        console.log(this.state.selectedFile);

        // Request made to the backend api
        // Send formData object
        axios.post(config.apiUrl + "/api/imageToPdf", formData, {responseType: 'blob'})
            .then((response) => {
                console.log('response', response)

                const link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(new Blob([response.data])));
                link.setAttribute('download', userFileName + ".pdf");

                link.style.display = 'none';

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                this.resetForm();
            });
    };

    // File content to be displayed after
    // file upload is complete
    fileData = () => {
        if (this.state.selectedFile) {
            return (
                <div>
                    <h2>File Details:</h2>
                    <p>File Name: {this.state.selectedFile.name}</p>
                    <p>File Type: {this.state.selectedFile.type}</p>
                    <p>
                        Last Modified:{" "}
                        {this.state.selectedFile.lastModifiedDate && this.state.selectedFile.lastModifiedDate.toDateString()}
                    </p>
                </div>
            );
        } else {
            return (
                <div>
                    <br/>
                    {this.state.atLeastOneDone &&
                    <Alert severity="success">Your file has downloaded, check your downloads folder. You can
                        now select another file to convert.</Alert>}
                </div>
            );
        }
    };

    render() {
        return (
            <div>
                <h2>
                    Image to Pdf Converter.
                </h2>
                <h3>
                    Supported Conversions: .png to .pdf, .jpg to .pdf, .jpeg to .pdf
                </h3>
                <h4>
                    This tool allows you to upload an image that will be converted into a PDF.
                </h4>
                <div style={{marginRight:"auto",marginLeft:"auto",width:"50%",marginTop:"50px"}}>
                    <label htmlFor="contained-button-file" style={{marginRight:"15px",marginBottom:"25px",display:"block"}}>
                        <Input accept="image/*" id="contained-button-file" type="file" onChange={this.onFileChange} />
                        <Button variant="contained" component="span">
                            Choose File
                        </Button>
                    </label>
                    {this.state.selectedFile && !this.state.converting &&
                    <Button variant="contained" onClick={() => {
                        this.onFileUpload(this.props.config)
                    }}>Convert File</Button>
                    }
                    {this.state.converting &&
                    <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                    >
                        Converting...
                    </LoadingButton>}
                </div>
                {this.fileData()}
            </div>
        );
    }
}

export default ImageToPdf