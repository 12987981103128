import './App.css';
import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import PdfTools from "./PdfTools";
import PrivacyPolicy from "./PrivacyPolicy";
import {createTheme} from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Paper} from "@mui/material";

const {REACT_APP_UI_ENV} = process.env;

const setupConfig = () => {
    if (REACT_APP_UI_ENV === "docker") {
        return {
            // apiUrl: "http://localhost:8080"
            apiUrl: window.document.location.protocol + "//" + window.document.location.host
        }
    } else {
        return {
            apiUrl: "http://localhost:3001"
        }
    }
}

const config = setupConfig();

class App extends Component {
    constructor(props) {
        super(props);
        this.theme = createTheme();
    }

    render() {
        return (
            <Router>
                <CssBaseline/>
                <div id="appContainer">
                    <ul id="navBar" style={{backgroundColor: this.theme.palette.primary.light}}>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/pdfTools">PDF Tools</Link>
                        </li>
                        <li>
                            <Link to="/privacy">Privacy Policy</Link>
                        </li>
                    </ul>
                    <div id="pageBody">
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/pdfTools" element={<PdfTools config={config}/>}/>
                            <Route path="/privacy" element={<PrivacyPolicy/>}/>
                        </Routes>
                    </div>
                </div>
            </Router>
        );
    }
}

function Home() {
    return (<div>
            <h2>Welcome to LazyFish</h2>
            <h3>This site is dedicated to bringing you valuable tools on the web for free!</h3>
            <Card sx={{maxWidth: 305,marginRight:"auto",marginLeft:"auto",marginTop:"50px"}}>
                <CardContent>
                    <Typography variant="h6">
                        Check out the PDF Converter
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button variant="outlined" href="/pdfTools">PDF Converter Tools</Button>
                </CardActions>
            </Card>
            <Paper style={{marginTop:"100px",maxWidth:"800px",marginLeft:"auto",marginRight:"auto",padding:"20px"}}>
                <Typography variant="h6">
                    How it works
                </Typography>
                <Typography variant="body1" style={{marginTop:"10px"}}>
                    Working with PDF's can be difficult, but it doesn't have to be!  Lazy Fish simple tools make it
                    possible for you to easily get your work done on time.  Do you need to submit your resume in a .pdf
                    format, but you only have it on your computer as a .png?  Use the free PDF Converter Tool and it
                    will convert your image into a PDF in no time.
                </Typography>
                <Typography variant="body1" style={{marginTop:"10px"}}>
                    Do you have a PDF file on your computer, but you need only 1 page as an image?  The PDF Converter
                    Tool is simple to use and it works fast!
                </Typography>
            </Paper>
        </div>
    )
}
//<Link to="/pdfTools">PDF Converter Tool</Link>

export default App;
