import {Component} from "react";
import ImageToPdf from "./ImageToPdf.js"
import PdfToImage from "./PdfToImage";

class PdfTools extends Component {
    render() {
        return (
            <div>
                <ImageToPdf config={this.props.config} />
                <PdfToImage config={this.props.config} />
            </div>
        )
    }
}

export default PdfTools;